import React, {useEffect, useState, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import MetaComponent from '../../components/common/MetaComponent';
import {fetchArticles} from '../../services/articleService';
import FeaturedCategoryArticles, {
  FeaturedCategoryArticlesSkeleton
} from '../../components/featured-category-articles/FeaturedCategoryArticles';
import ArticlesGrid from '../../components/articles-grid/ArticlesGrid';
import {Typography} from '@material-tailwind/react';

export default function Category() {
  const {categorySlug} = useParams();
  const {categories} = useSelector((state) => state.categories);
  const [category, setCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (categories.length) {
      const foundCategory = categories.find(cat => cat.slug === categorySlug);
      if (!foundCategory) {
          navigate('/not-found', {replace: true});
          return;
      }

      setCategory(foundCategory);
      setCategoryId(foundCategory.id);
    }
  }, [categorySlug, categories]);

  const metadata = useMemo(() => ({
    title: `${category ? category.name : 'Loading...'} - Balkanski Mozaik`
  }), [category]);

  const featuredCategoryArticles = useMemo(() => {
    if (!categoryId) return <FeaturedCategoryArticlesSkeleton/>;
    return (
      <FeaturedCategoryArticles
        fetchArticles={() => fetchArticles({
          category: categoryId,
          isFeaturedInCategory: true,
          limit: 4
        }).then(data => data.docs)}
      />
    );
  }, [categoryId]);

  const articlesGrid = useMemo(() => {
    if (!category) return null;
    return (
      <ArticlesGrid
        fetchArticles={fetchArticles}
        fetchArticleArgs={{category: categoryId, isFeaturedInCategory: false, limit: 5}}
      />
    );
  }, [categoryId]);

  if (!category) {
    return (
      <div className="pt-6 pb-4">
        <Typography
          as="div"
          variant="h1"
          className="text-2xl sm:text-3xl md:text-4x w-1/3 bg-gray-400 mb-3 rounded-lg animate-pulse"
        >
          &nbsp;
        </Typography>
        <FeaturedCategoryArticlesSkeleton/>
      </div>
    );
  }

  return (
    <>
      <MetaComponent meta={metadata}/>
      <div className="pt-6 pb-4">
         <h1 className={'text-2xl sm:text-3xl md:text-4x mb-3 font-bold'}>{category.name}</h1>
         {featuredCategoryArticles}
         {articlesGrid}
      </div>
    </>
  );
}
