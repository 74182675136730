import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Collapse, IconButton, Navbar as MaterialNavbar, Typography} from '@material-tailwind/react';
import {Bars3Icon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {useSelector} from 'react-redux';
import SearchInput from '../search-input/SearchInput';

const NavList = ({onItemClick}) => {
  const {categories} = useSelector((state) => state.categories);

  const handleOnItemClicked = () => {
    if (onItemClick) {
      onItemClick();
    }
  };

  return (
    <ul className="my-2.5 px-2.5 xl:px-0 flex flex-col gap-x-8 gap-y-2.5 md:flex-row items-center">
      {
        categories.map(category => (
          <Typography
            as="li"
            variant="paragraph"
            color="gray"
            className="font-semibold"
            key={category.slug}
          >
            <Link to={`/${category.slug}`}
                  className={`flex items-center transition-colors`}
                  onClick={handleOnItemClicked}>
              {category.name}
            </Link>
          </Typography>
        ))
      }
    </ul>
  );
};

const Navbar = (props) => {
  const [openNav, setOpenNav] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 1140 && setOpenNav(false);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const closeNavbar = () => {
    setOpenNav(false);
    setSearchOpen(false);
  };

  const openSearch = () => {
    setSearchOpen(true);
  };

  const openNavbar = () => {
    setOpenNav(true);
  };

  return (
    <MaterialNavbar
      className={`mx-auto relative py-0 px-0 bg-white max-w-full bg-opacity-100 border-0 w-screen border-b shadow-md overflow-hidden rounded-none ${props.className}`}>
      <div
        className="flex py-3 px-2 md:px-4 lg:px-0 items-center max-w-screen-2xl md:container justify-between gap-10 mx-auto text-gray-900">
        <Link to={'/'} className={'opacity-75'}>
          <img src="/images/logo.svg" className={'w-28 md:w-36'} alt="logo"/>
        </Link>
        <div className="hidden xl:block">
          <NavList/>
        </div>
        <div className="hidden xl:flex items-center">
          <SearchInput/>
        </div>
        <span className={'flex gap-6 xl:hidden'}>
          {!openNav && !searchOpen && <IconButton
              variant="text"
              className="flex w-6 h-6 justify-center items-center text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent"
              ripple={false}
              onClick={openSearch}
          >
              <MagnifyingGlassIcon  className="h-6 w-6" strokeWidth={2}/>
          </IconButton>
          }
          {
              !openNav && !searchOpen && <IconButton
                variant="text"
                className="flex w-6 h-6 justify-center items-center text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent"
                ripple={false}
                onClick={openNavbar}
            >
                  <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
            </IconButton>
          }
          {
            (!!openNav || !!searchOpen) && <IconButton
                variant="text"
                className="flex w-6 h-6 justify-center items-center text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent"
                ripple={false}
                onClick={closeNavbar}
            >
              <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
            </IconButton>
          }
        </span>
      </div>
      <Collapse open={searchOpen} className={'xl:hidden'}>
        <div className="py-4 px-2 md:px-3 lg:px-0 w-full mx-auto flex md:container ">
          <SearchInput isFullWidth={true}/>
        </div>
      </Collapse>
      <Collapse open={openNav} className={'xl:hidden'}>
        <NavList onItemClick={closeNavbar}/>
      </Collapse>
    </MaterialNavbar>
  );
};

export default Navbar;
