import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import HorizontalArticleCard, {HorizontalArticleCardSkeleton} from '../article/HorizontalArticleCard';
import {useInView} from 'react-intersection-observer';
import {Typography} from '@material-tailwind/react';
import {Link} from 'react-router-dom';

const CategorySidebarSection = ({title, titleUrl, fetchArticles}) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  useEffect(() => {
    if (fetchArticles && inView) {
      const loadArticles = async () => {
        setIsLoading(true);
        try {
          const data = await fetchArticles();
          setArticles(data);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      loadArticles();
    }
  }, [fetchArticles, inView]);

  if (isLoading || !inView) {
    return (
      <div ref={ref} className={'mt-2 mb-6 sm:mb-8 md:mt-0 xl:my-0 animate-pulse'}>
        <Typography
          as="div"
          variant="h1"
          className="text-2xl md:text-3xl mb-1 md:mb-2 lg:mb-4 w-2/3 bg-gray-300 rounded-lg xl:hidden"
        >
          &nbsp;
        </Typography>
        <div
          className={`flex flex-col gap-2 lg:gap-2.5 xl:gap-4'}`}>
          <HorizontalArticleCardSkeleton/>
          <HorizontalArticleCardSkeleton/>
          <HorizontalArticleCardSkeleton/>
          <HorizontalArticleCardSkeleton/>
          <HorizontalArticleCardSkeleton/>
        </div>
      </div>
    );
  }

  return (
    <div ref={ref}>
      <Link to={titleUrl} className={' xl:hidden'}>
        <h2 className="mb-1.5 text-xl sm:text-3xl font-bold text-gray-500">{title}</h2>
      </Link>
      <div
        className={`flex flex-col md:grid md:grid-cols-2 xl:flex xl:flex-col gap-x-4 gap-y-2`}>
        {articles && articles.map(article => (
          <HorizontalArticleCard article={article} key={article.id} className="h-full"/>
        ))}
      </div>
    </div>
  );
};

CategorySidebarSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleUrl: PropTypes.string.isRequired,
  fetchArticles: PropTypes.func.isRequired
};

export default CategorySidebarSection;
