import React from 'react';
import MetaComponent from '../../components/common/MetaComponent';
import {Typography} from '@material-tailwind/react';

export default function PrivacyPolicy() {
  const metadata = {
    title: 'Politika privatnosti - Balkanski Mozaik'
  };

  return (
    <>
      <MetaComponent meta={metadata}/>
      <div className="container mx-auto pt-10 md:pt-12 lg:pt-16 pb-2 overflow-hidden">
        <Typography variant="h1" className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 md:mb-8 lg:mb-12">
          Politika privatnosti
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          -
        </Typography>
        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          -
        </Typography>

      </div>
    </>
  );
}
