import {Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home.jsx';
import NotFound from './pages/not-found/NotFound';
import Article from './pages/article/Article.jsx';
import Category from './pages/category/Category';
import Impressum from './pages/impressum/Impressum';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Search from './pages/search/Search';

export default function RoutesComponent() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/search" element={<Search/>}/>
      <Route path="/impressum" element={<Impressum/>}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="/not-found" element={<NotFound/>}/>
      <Route path="/:categorySlug" element={<Category/>}/>
      <Route path="/:categorySlug/:articleSlug" element={<Article/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  );
}
