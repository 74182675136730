import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Typography} from '@material-tailwind/react';

const FeaturedCategoryArticles = ({fetchArticles}) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const data = await fetchArticles();
        setArticles(data);
      } catch (error) {
        console.error('Error fetching featured articles:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadArticles();
  }, [fetchArticles]);

  if (isLoading) {
    return (
      <FeaturedCategoryArticlesSkeleton/>
    );
  }

  if (!articles || articles.length < 4) {
    return null;
  }

  return (
    <div className="h-screen md:h-64 lg:h-96 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 grid-rows-4 sm:grid-rows-2 md:grid-rows-1 gap-2.5 mb-4">
      {articles.map((article, index) => (
        <div key={index} className="col-span-1 row-span-1 relative h-full rounded-lg overflow-hidden shadow">
          <Link to={`/${article.category.slug}/${article.slug}`}>
            <div className="h-full">
              <div className="h-full">
                <div className="overflow-hidden h-full">
                  <img src={article.coverImages[0].url} className="h-full w-full object-cover" alt={article.title}/>
                </div>
                <div
                  className="absolute bottom-0 left-0 p-3 pt-10 w-full flex flex-col justify-end text-white bg-gradient-to-t from-[rgba(0,0,0,0.8)] via-[rgba(0,0,0,0.6)] to-[rgba(0,0,0,0)]">
                  <h3 className="text-xl lg:text-2xl text-white drop-shadow line-clamp-2">{article.title}</h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export function FeaturedCategoryArticlesSkeleton() {
  return (
    <div
      className="h-screen lg:h-96 grid grid-cols-1 md:grid-cols-4 grid-rows-4 md:grid-rows-1 gap-2.5 animate-pulse mb-4">
      <div className="h-full relative col-span-1 row-span-1 rounded-lg bg-gray-300">
        <Typography
          as="div"
          variant="h1"
          className="text-xl lg:text-2xl w-2/3 bg-gray-400 absolute bottom-3 left-3 rounded-lg"
        >
          &nbsp;
        </Typography>
      </div>
      <div className="h-full relative col-span-1 row-span-1 rounded-lg bg-gray-300">
        <Typography
          as="div"
          variant="h1"
          className="text-xl lg:text-2xl w-2/3 bg-gray-400 absolute bottom-3 left-3 rounded-lg"
        >
          &nbsp;
        </Typography>
      </div>
      <div className="h-full relative col-span-1 row-span-1 rounded-lg bg-gray-300">
        <Typography
          as="div"
          variant="h1"
          className="text-xl lg:text-2xl w-2/3 bg-gray-400 absolute bottom-3 left-3 rounded-lg"
        >
          &nbsp;
        </Typography>
      </div>
      <div className="h-full relative col-span-1 row-span-1 rounded-lg bg-gray-300">
        <Typography
          as="div"
          variant="h1"
          className="text-xl lg:text-2xl w-2/3 bg-gray-400 absolute bottom-3 left-3 rounded-lg"
        >
          &nbsp;
        </Typography>
      </div>
    </div>
  );
}

FeaturedCategoryArticles.propTypes = {
  fetchArticles: PropTypes.func.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      link: PropTypes.string.isRequired,
      coverImage: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired,
      category: PropTypes.shape({
        slug: PropTypes.string.isRequired
      }).isRequired
    })
  )
};

export default FeaturedCategoryArticles;
