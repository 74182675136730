import {Link} from 'react-router-dom';

export default function PageNotFound() {
    return (
        <>
            <style>{`
                .custom-blue {
                    background-color: rgba(64, 104, 130, 1);
                 }

                .custom-blue:hover {
                    background-color: rgba(44, 84, 110, 1); 
                 }
            `}
            </style>

            <div
                className="flex flex-col justify-between items-center text-center pt-12 pb-8 h-auto">
                <div className="flex flex-col items-center">
                    <h3 className="text-5xl font-bold text-gray-800 opacity-80 mb-4">
                        Oops!
                    </h3>

                    <h2 className="text-xl xl:text-2xl font-semibold text-gray-700 opacity-80 mb-8">
                        Tražena stranica nije pronađena.
                    </h2>

                    <img
                        src="/images/404.png"
                        alt="404 Not Found"
                        className="mb-8 mx-4 w-10/12 md:w-1/2 lg:w-2/5 xl:w-1/3 "
                    />

                    <p className="text-xs xl:text-md font-semibold text-gray-600">
                        Možda je link do stranice nevažeći ili je stranica uklonjena.
                    </p>
                    <p className="text-xs xl:text-md font-semibold text-gray-600 mb-12">
                        Pokušajte ponovo sa pretragom ili se vratite na početnu stranicu.
                    </p>
                </div>

                <Link
                    to="/"
                    className="custom-blue text-white font-medium rounded-full px-12 py-2 text-base transition-all"
                >
                    Nazad na početnu stranicu
                </Link>
            </div>
        </>
    );
}
