import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Input} from '@material-tailwind/react';
import {MagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/24/outline';

const SearchInput = ({isFullWidth}) => {
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchQuery) {
      return;
    }

    navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    if (!searchParams.get('q')) {
      setSearchQuery('');
    }
  }, [searchParams]);

  return (
      <form onSubmit={handleSearch} className={`flex items-center w-full ${!isFullWidth && 'max-w-xs'}`}>
        <div className={`${!isFullWidth ? 'w-52' : 'w-full'} relative`}>
          <Input
              label="Pretražite"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pr-16"
              icon={
                <MagnifyingGlassIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={handleSearch}
                />
              }
          />
          {searchQuery && (
              <XMarkIcon
                  className="absolute right-10 top-1/2 transform -translate-y-1/2 h-6 w-6 cursor-pointer text-blue-gray-500"
                  onClick={clearSearch}
              />
          )}
        </div>
      </form>
  );
};

export default SearchInput;
