import {configureStore} from '@reduxjs/toolkit';
import categoryReducer from './features/categories/categorySlice';
import articleReducer from './features/articles/articleSlice';

const store = configureStore({
  reducer: {
    categories: categoryReducer,
    articles: articleReducer
  }
});

export default store;
