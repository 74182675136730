import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import Lightbox from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import {Thumbnails} from 'yet-another-react-lightbox/plugins';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import React, {useEffect, useState} from 'react';

const ImageGallery = ({images, imageCreditTexts, imageCreditLinks, className}) => {

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    setImageIndex(0);
  }, [images]);

  const toggleOpen = (state) => () => setLightboxOpen(state);

  const updateIndex = ({index}) => setImageIndex(index);

  const imageCredit = (image, index) => {
    if (!imageCreditTexts[index]) {
      return '';
    }

    return imageCreditLinks[index] ? <a href={imageCreditLinks[index]} target={'_blank'}
                                        rel={'noopener noreferrer'}>Foto: {imageCreditTexts[index]}</a> : `Foto: ${imageCreditTexts[index]}`;
  };

  const lightboxImages = images.map((image, index) => ({
    src: image.url,
    description: imageCredit(image, index),
    srcSet: [
      {
        src: image.url
      }
    ]
  }));

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const carouselLightboxPlugins = [Inline, Captions];

  if (lightboxImages.length > 1) {
    carouselLightboxPlugins.push(Counter);
  }

  const carouselLightboxIcons = {};

  if (lightboxImages.length === 1) {
    carouselLightboxIcons.iconPrev = () => <></>;
    carouselLightboxIcons.iconNext = () => <></>;
  }

  return (
    <>
      <div className={'relative rounded-lg overflow-hidden cursor-pointer'}>
        <Lightbox
          index={imageIndex}
          slides={lightboxImages}
          plugins={carouselLightboxPlugins}
          on={{
            view: updateIndex,
            click: toggleOpen(true)
          }}
          carousel={{
            padding: 0,
            spacing: 0,
            imageFit: 'cover',
            finite: true
          }}
          inline={{
            style: {
              width: '100%',
              aspectRatio: '3 / 2',
              margin: '0 auto'
            }
          }}
          render={carouselLightboxIcons}
        />
      </div>

      <Lightbox
        open={lightboxOpen}
        index={imageIndex}
        plugins={[Thumbnails, Counter, Captions]}
        close={closeLightbox}
        slides={lightboxImages}
        thumbnails={{preload: 1, padding: 0, width: 'auto', showToggle: true}}
        captions={{descriptionMaxLines: 3}}
      />
    </>
  );
};

export default ImageGallery;
