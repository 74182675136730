import React from 'react';
import {useEffect} from 'react';
import Routes from './Routes';
import Navbar from './components/navbar/Navbar';
import Headroom from 'react-headroom';
import {useDispatch} from 'react-redux';
import {fetchCategories} from './features/categories/categorySlice.js';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import Footer from './components/footer/Footer';
import ReactGA from 'react-ga4';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories({page: 1, limit: 100}));
  }, [dispatch]);

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

  return (
    <div className={'flex flex-col min-h-screen'}>
      <ScrollToTop/>
      <div className={'w-full'}>
        <Headroom className={'absolute z-50 top-0 left-0 w-full'}>
          <Navbar/>
        </Headroom>
      </div>
        <div className="flex-grow container mx-auto overflow-hidden px-2.5 md:px-4 lg:px-0 pt-10 md:pt-14 lg:pt-16">
            <Routes/>
        </div>
        <Footer/>
    </div>
  );
}

export default App;
