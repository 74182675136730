import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

export const fetchArticle = createAsyncThunk('articles/fetchArticle', async (id, {getState, rejectWithValue}) => {
  const {articlesById} = getState().articles;

  if (articlesById[id]) {
    return articlesById[id];
  }

  try {
    const response = await axiosInstance.get(`/articles/${id}`, {params: {populate: 'author,category,coverImages'}});
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchArticles = createAsyncThunk('articles/fetchArticles', async ({page}, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.get('/articles', {params: {page, populate: 'author,category,coverImages'}});
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  articlesById: {},
  articles: [],
  article: undefined,
  totalPages: 1,
  totalDocs: 0,
  isLoading: false,
  error: null
};

const articleSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticle.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articlesById[action.payload.id] = action.payload;
        state.article = action.payload;
      })
      .addCase(fetchArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchArticles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.articles = action.payload.docs;
        state.totalPages = action.payload.totalPages;
        state.totalDocs = action.payload.totalDocs;
        action.payload.docs.forEach(article => {
          state.articlesById[article.id] = article;
        });
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default articleSlice.reducer;
