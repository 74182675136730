import React from 'react';
import {useSelector} from 'react-redux';
import MetaComponent from '../../components/common/MetaComponent';
import FeaturedArticles from '../../components/featured-articles/FeaturedArticles';
import CategorySection from '../../components/category-section/CategorySection';
import {fetchArticles} from '../../services/articleService';

export default function Home() {
  const metadata = {
    title: 'Početna - Balkanski Mozaik'
  };

  const {categories} = useSelector((state) => state.categories);

  const predefinedCategories = [
    {title: 'Najnovije', category: 'newest', titleUrl: '/', fetchArticles: () => fetchArticles({limit: 5}).then(data => data.docs)}
  ];

  return (
    <>
      <MetaComponent meta={metadata}/>
      <div>
        {
          !!categories.length &&
          <FeaturedArticles fetchArticles={() => fetchArticles({isFeatured: true, limit: 4}).then(data => data.docs)}/>
        }
        {!!categories.length && predefinedCategories.map(({title, titleUrl, category, fetchArticles}) => (
          <CategorySection key={category} title={title} titleUrl={titleUrl} fetchArticles={() => fetchArticles()}/>
        ))}

        {categories.map((category) => (
          <CategorySection key={category.id} title={category.name} titleUrl={`/${category.slug}`}
                           fetchArticles={() => fetchArticles({category: category.id, limit: 5}).then(data => data.docs)}/>
        ))}
      </div>
    </>
  );
}
