import {Link} from 'react-router-dom';
import {formatTimeAgo} from '../../utils/formatTime.js';
import React from 'react';
import {Typography} from '@material-tailwind/react';

export default function HorizontalArticleCard({article, className}) {
  return (
    <Link to={`/${article.category.slug}/${article.slug}`}>
      <div
        className={`border border-gray-100 min-h-24 w-full shadow-sm hover:shadow-md rounded-lg transition-all grid grid-cols-3 overflow-hidden ${className || ''}`}>
        <div className={'relative w-full'}>
          <img
            src={article.coverImages[0].url}
            alt={article.title}
            className={'object-cover w-full h-full'}
          />
        </div>
        <div className={'grow flex flex-col justify-between col-span-2 px-2 pt-1.5 pb-1'}>
          <div>
            <div
              className={'font-semibold text-sm text-red-500'}>
              {article.category.name}
            </div>
            <div className={'text-gray-900 font-semibold leading-5 line-clamp-2 my-3'}>
              {article.title}
            </div>
          </div>
          <div className={'text-sm font-semibold text-gray-500 text-opacity-75'}>
            {formatTimeAgo(new Date(article.createdAt))}
          </div>
        </div>
      </div>
    </Link>
  );
}

export function HorizontalArticleCardSkeleton() {
  return (
    <div
      className={`border border-gray-300 rounded-lg overflow-hidden min-h-24 w-full hover:shadow-md transition-all grid grid-cols-3`}>
      <div className={'bg-gray-100 w-full'}>

      </div>
      <div className={'h-32 flex flex-col justify-between col-span-2 px-2 pt-1.5 pb-1'}>
        <div>
          <Typography
            as="div"
            variant="h1"
            className="leading-4 w-2/5 bg-gray-300 rounded-lg"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="h1"
            className="w-full leading-5 bg-gray-300 mt-1 mb-1 rounded-lg"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="h1"
            className="w-4/5 leading-5 bg-gray-300 mb-1 rounded-lg"
          >
            &nbsp;
          </Typography>
        </div>
        <div className={'text-sm'}>
          <Typography
            as="div"
            variant="h1"
            className="leading-4 w-1/3 bg-gray-300 rounded-lg"
          >
            &nbsp;
          </Typography>
        </div>
      </div>
    </div>
  );
}
