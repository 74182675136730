import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async ({
                                                                                       page,
                                                                                       limit
                                                                                     }, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.get('/categories', {params: {page, limit}});
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchCategory = createAsyncThunk('categories/fetchCategory', async (id, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.get(`/categories/${id}`);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const createCategory = createAsyncThunk('categories/createCategory', async (categoryData, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.post('/categories', categoryData);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updateCategory = createAsyncThunk('categories/updateCategory', async ({
                                                                                     id,
                                                                                     ...categoryData
                                                                                   }, {rejectWithValue}) => {
  try {
    const response = await axiosInstance.patch(`/categories/${id}`, categoryData);
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const toggleFeatured = createAsyncThunk('categories/toggleFeatured', async (id, {getState, rejectWithValue}) => {
  const {categoriesById} = getState().categories;
  const category = categoriesById[id];

  try {
    const response = await axiosInstance.patch(`/categories/${id}`, {isFeatured: !category.isFeatured});
    return response.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  categoriesById: {},
  categories: [],
  category: {},
  totalPages: 1,
  totalDocs: 0,
  isLoading: false,
  error: null
};

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload.docs;
        state.totalPages = action.payload.totalPages;
        state.totalDocs = action.payload.totalDocs;
        action.payload.docs.forEach(category => {
          state.categoriesById[category.id] = category;
        });
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoriesById[action.payload.id] = action.payload;
        state.category = action.payload;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories.push(action.payload);
        state.categoriesById[action.payload.id] = action.payload;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoriesById[action.payload.id] = action.payload;
        const index = state.categories.findIndex(category => category.id === action.payload.id);
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(toggleFeatured.pending, (state) => {
        state.error = null;
      })
      .addCase(toggleFeatured.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        state.categories = state.categories.map(category =>
          category.id === updatedCategory.id ? updatedCategory : category
        );
        state.categoriesById[updatedCategory.id] = updatedCategory;
      })
      .addCase(toggleFeatured.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
});

export default categorySlice.reducer;
