import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ArticleCard, {ArticleCardSkeleton} from '../article/ArticleCard';
import {useInView} from 'react-intersection-observer';
import {Typography} from '@material-tailwind/react';
import {Link} from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const CategorySection = ({title, titleUrl, fetchArticles}) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  useEffect(() => {
    if (fetchArticles && inView) {
      const loadArticles = async () => {
        setIsLoading(true);
        try {
          const data = await fetchArticles();
          setArticles(data);
          setIsLoading(false);
        } catch (error) {
          setError(error);
          setIsLoading(false);
        }
      };

      loadArticles();
    }
  }, [fetchArticles, inView]);

  if (isLoading || !inView) {
    return (
      <div ref={ref} className={'my-6 md:my-8 lg:my-12 animate-pulse'}>
         <Typography
            as="div"
            variant="h1"
            className="text-2xl md:text-3xl w-2/3 md:w-1/4 bg-gray-300 rounded-lg  mb-2"
        >
           &nbsp;
        </Typography>
        <div className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-2.5 xl:gap-4">
          <ArticleCardSkeleton/>
          <ArticleCardSkeleton/>
          <ArticleCardSkeleton/>
          <ArticleCardSkeleton/>
          <ArticleCardSkeleton/>
        </div>
      </div>
    );
  }

  return (
      <div ref={ref} className="my-6">
        <div className={'flex justify-between items-center mb-2'}>
          <Link to={titleUrl} className="flex-grow-1">
            <h3 className="font-semibold text-2xl md:text-3xl text-gray-800">{title}</h3>
          </Link>
          <a className={`flex items-center text-gray-800 ${titleUrl === '/' && 'hidden'} cursor-pointer`} href={titleUrl}>
            <span className={'hidden sm:block'}>Otvori sve članke</span>
            <ArrowRightIcon className="h-7 w-7 sm:ml-2 sm:h-5 sm:w-5 text-gray-800 opacity-90" />
          </a>
        </div>

        <div className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-2.5 xl:gap-4">
          {articles && articles.map(article => (
              <ArticleCard article={article} key={article.id}/>
          ))}
        </div>
      </div>
  );
};

CategorySection.propTypes = {
  title: PropTypes.string.isRequired,
  titleUrl: PropTypes.string.isRequired,
  fetchArticles: PropTypes.func.isRequired
};

export default CategorySection;
