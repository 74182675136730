import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function MetaComponent({ meta }) {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{meta?.title}</title>
                <meta name="description" content={meta?.description}/>
                <meta name="keywords" content={meta?.keywords}/>
                <meta property="og:title" content={meta?.title}/>
                <meta property="og:description" content={meta?.description}/>
                <meta property="og:image" content={meta?.image}/>
                <meta property="og:url" content={meta?.url}/>
                <meta property="og:type" content="article"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={meta?.title}/>
                <meta name="twitter:description" content={meta?.description}/>
                <meta name="twitter:image" content={meta?.image}/>
            </Helmet>
        </HelmetProvider>
    );
}
